import {
	PAGE_PATH_GUEST_ORDER,
	PAGE_PATH_REGISTER,
	QUERY_PARAM_PREVIOUS_PATH,
	RESTRICTION_MODE_LOGIN_REGISTER,
} from '@bluheadless/ui-logic/src/constants'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { cx } from '@emotion/css'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/system'
import { useRouter } from 'next/router'
import { FormattedMessage } from 'react-intl'
import Button from '../../../molecules/button'
import Link from '../../../molecules/link'
import { GuestOrderLink, NotRegistered, Root, Subtitle, Title } from './form.styled'

const GuestInfo = (className) => {
	const theme = useTheme()
	const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

	const {
		catalog: { disableShop },
		checkout: { disableGuest },
		restriction: { enabled: restrictionEnabled, mode: restrictionMode },
	} = useConfig()

	const router = useRouter()

	const {
		query: { [QUERY_PARAM_PREVIOUS_PATH]: previousPath },
	} = router

	return (
		<Root className={cx('GuestInfo-root', className)}>
			{(!restrictionEnabled || (restrictionEnabled && restrictionMode === RESTRICTION_MODE_LOGIN_REGISTER)) && (
				<NotRegistered>
					<Title component="h2" variant={lgUp ? 'h4' : 'h1'}>
						<FormattedMessage id="guest_notregistered_title" />
					</Title>
					<Subtitle variant="body1">
						<FormattedMessage id="guest_notregistered_subtitle" />
					</Subtitle>
					<Button
						color="white"
						component={Link}
						href={`${PAGE_PATH_REGISTER}${previousPath ? `?${QUERY_PARAM_PREVIOUS_PATH}=${previousPath}` : ''}`}
						fullWidth
					>
						<FormattedMessage id="guest_notregistered_button" />
					</Button>
				</NotRegistered>
			)}

			{!disableShop && !disableGuest && (
				<GuestOrderLink>
					<Title component="h3" variant={lgUp ? 'h4' : 'h1'}>
						<FormattedMessage id="guest_view_order_title" />
					</Title>
					<Subtitle variant="body1">
						<FormattedMessage id="guest_view_order_subtitle" />
					</Subtitle>
					<Button variant="outlined" component={Link} href={PAGE_PATH_GUEST_ORDER}>
						<FormattedMessage id="guest_view_order_button" />
					</Button>
				</GuestOrderLink>
			)}
		</Root>
	)
}

export default GuestInfo
