import { AUTH_ACTION } from '@bluheadless/ui-logic/src/constants'
import withAuthUserSSR from '@bluheadless/ui-logic/src/hof/withAuthUserSSR'
import { useTrackingsActions } from '@bluheadless/ui-logic/src/hooks/trackings/useTrackingsActions'
import LoginPageComponent from '@bluheadless/ui/src/page-components/customer/guest/login'
import Head from 'next/head'
import { useIntl } from 'react-intl'
// eslint-disable-next-line import/no-unresolved
import { getDynamicAreas } from 'data-api'
import { useEffect } from 'react'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { GUEST_LAYOUT } from '@bluheadless/ui/src/layouts/variants'
import { usePageContext } from '@bluheadless/ui-logic/src/providers/page/page'

const Login = (props) => {
	const { formatMessage } = useIntl()

	const { pageViewDetails } = useTrackingsActions()
	const { entityType: pageContext, __bhEntityId: pageId } = usePageContext()
	const { locale } = useConfig()

	useEffect(() => {
		pageViewDetails({ pageType: 'login', params: { locale }, pageContext, pageId })
	}, [pageViewDetails, locale, pageContext, pageId])

	return (
		<>
			<Head>
				<title>{formatMessage({ id: 'login_meta_title' })}</title>
				<meta name="robots" content="noindex,nofollow" />
			</Head>
			<LoginPageComponent {...props} />
		</>
	)
}

export default Login

export const getServerSideProps = withAuthUserSSR(
	{
		whenAuthenticated: AUTH_ACTION.REDIRECT_TO_ACCOUNT,
		whenUnauthenticated: AUTH_ACTION.RENDER,
	},
	async ({ req }) => {
		const getDynamicAreasLogin = (
			await getDynamicAreas({
				client: req.__bh__client,
				dynamicAreasDisplayEntity: 'service_pages',
				dynamicAreasId: 'login',
			})
		).data

		const getDynamicAreasRegister = (
			await getDynamicAreas({
				client: req.__bh__client,
				dynamicAreasDisplayEntity: 'service_pages',
				dynamicAreasId: 'register',
			})
		).data

		const [dynamicAreasLogin, dynamicAreasRegister] = await Promise.all([getDynamicAreasLogin, getDynamicAreasRegister])

		return {
			props: {
				dynamicAreasLogin,
				dynamicAreasRegister,
			},
		}
	}
)

Login.layout = { cssClass: 'login-page' }

Login.withLayout = GUEST_LAYOUT
